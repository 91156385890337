import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '@/views/landing/LandingView.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'Landing',
			component: LandingView,
			children: [
				{
					path: '',
					name: 'Landing Home',
					component: () => import('@/views/landing/HomeView.vue')
				},
				{
					path: 'privacy-policy',
					name: 'Privacy Policy',
					component: () => import('@/views/landing/PrivacyPolicyView.vue')
				}
			]
		},
		{
			path: '/scene',
			name: 'Scene',
			component: () => import('@/views/SceneView.vue'),
			children: [
				{
					path: '',
					name: 'Home',
					component: () => import('@/views/HomeView.vue')
				},
				{
					path: 'sign-up',
					name: 'SignUp',
					component: () => import('@/views/SignUpView.vue')
				},
				{
					path: 'login',
					name: 'Login',
					component: () => import('@/views/LoginView.vue')
				},
				{
					path: 'external',
					name: 'External Login',
					component: () => import('@/components/Login/ExternalLogin.vue')
				},
				{
					path: 'external-error',
					name: 'External Login Error',
					component: () => import('@/components/Login/ExternalLoginError.vue')
				},
				{
					path: 'webar',
					name: 'WebAR',
					component: () => import('@/views/WebARView.vue')
				},
				{
					path: 'constructor',
					name: 'Constructor',
					component: () => import('@/views/ConstructorView.vue')
				},
				{
					path: 'constructor/search',
					name: 'Constructor Search',
					component: () => import('@/views/SearchView.vue')
				},
				{
					path: 'character',
					name: 'Character',
					component: () => import('@/views/CharacterView.vue')
				},
				{
					path: 'character/search',
					name: 'Character Search',
					component: () => import('@/views/SearchView.vue')
				},
				{
					path: 'favourite',
					name: 'Favourite',
					component: () => import('@/views/FavouriteView.vue')
				},
				{
					path: 'favourite/search',
					name: 'Favourite Search',
					component: () => import('@/views/SearchView.vue')
				},
				{
					path: 'profile',
					name: 'Profile',
					component: () => import('@/views/ProfileView.vue')
				},
				{
					path: 'contact-us',
					name: 'Contact Us',
					component: () => import('@/views/ContactUsView.vue')
				},
				{
					path: 'scan-qr',
					name: 'Scan QR',
					component: () => import('@/views/ScanQRView.vue')
				}
			]
		},
		{
			path: '/g/:code',
			name: 'Gift',
			component: () => import('@/views/GiftView.vue')
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'Not Found',
			component: () => import('@/views/landing/NotFoundView.vue')
		}
	]
})

export default router
