import { clickOutsideDirective } from '@/helpers/directives'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVfm } from 'vue-final-modal'

import App from '@/App.vue'
import router from '@/router'
import i18n from '@/i18n'
import { register as registerSwiper } from 'swiper/element/bundle'
import { defineGlobalValidators } from '@/helpers/landing/defineGlobalValidators.js'

const app = createApp(App)

app.use(createPinia())
app.use(createVfm())
app.use(router)
app.use(i18n)
app.directive('click-outside', clickOutsideDirective)
registerSwiper()
defineGlobalValidators()

import '@/aframe-components/tap-to-place.js'

app.mount('#app')
