export const getFromCache = (key = '') => {
	return localStorage.getItem(key)
}
export const saveToCache = (key = '', value) => {
	localStorage.setItem(key, value)
}

export const removeFromCache = (key = '') => {
	localStorage.removeItem(key)
}
