import { configure, defineRule } from 'vee-validate'
import { required, email } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import ruMessages from '@/i18n/messages/landing/ru'
import enMessages from '@/i18n/messages/landing/en'
import plMessages from '@/i18n/messages/landing/pl'
import deMessages from '@/i18n/messages/landing/de'
import i18n from '@/i18n'

export function defineGlobalValidators() {
	defineRule('required', required)
	defineRule('email', email)
}

configure({
	generateMessage: localize({
		ru: {
			messages: {
				...ruMessages.validator
			}
		},
		en: {
			messages: {
				...enMessages.validator
			}
		},
		pl: {
			messages: {
				...plMessages.validator
			}
		},
		de: {
			messages: {
				...deMessages.validator
			}
		}
	})
})

setLocale(i18n.global.locale)
