import { HOST, request } from '@/api/index.js'

export const getCurrentCountryCode = async () => {
	const url = `${HOST}/country/current`

	return request(async () => {
		const response = await fetch(url)

		if (response.ok) {
			const data = await response.json()

			return data.country_code
		}

		return false
	})
}
