import landingEn from './landing/en'

export const enMessages = {
	en: {
		base: {
			cancel: 'Cancel',
			back: 'Back',
			close: 'Close',
			next: 'Next'
		},
		characters: {
			roxxid: 'Roxid',
			['cute girl']: 'Cutie',
			birdman: 'Birdman',
			skeleton: 'Skeleton'
		},
		home: {
			collections: 'Collections',
			myHeroes: 'My heroes'
		},
		profile: {
			edit: 'Edit',
			chooseLang: 'Select a language',
			logout: 'Log out',
			collectedHeroes: 'Collected heroes'
		},
		editPhone: {
			enterPhone: 'Enter phone number',
			save: 'Save',
			incorrectNumber: 'Incorrect number entered',
			confirmation: 'Confirmation',
			smsSent: 'SMS with the code was sent to the specified number',
			codeHint: 'To complete the number change, enter the 6-digit activation code',
			secondsRemaining: 'sec',
			hoursRemaining: 'hour',
			sendCodeAgain: 'Request code again',
			sendCodeAgainAfter: 'Request code again after {count} {type}',
			send: 'Send',
			phoneChanged: 'The number was successfully changed',
			myProfile: 'My profile',
			incorrectCode: 'Invalid code',
			invalidCode: 'Incorrect code entered'
		},
		greenSuccessModal: {
			title: 'Congratulations',
			buttonText: 'Ok'
		},
		menu: {
			titleCollections: 'Collections',
			collections: 'Monzi - collections',
			myHeroes: 'My heroes',
			favorites: 'Favorites',
			scan: 'Scan',
			profile: 'Profile',
			contactUs: 'Contact us',
			addApplicationIcon: 'Add an application icon'
		},
		contactUs: {
			title: 'Do you have a question?',
			desc: 'Ask a question to support',
			yourQuestion: 'Your question',
			send: 'Send',
			invalidEmail: 'Email is entered incorrectly'
		},
		energyModal: {
			title: 'Earn your energy!',
			desc: 'Click on a character, earn energy, and buy animations',
			button: 'My heroes'
		},
		coinModal: {
			title: 'Earn cookies!',
			desc: 'Cookies are the in-game currency. With cookies you can buy characters in the constructor. You can also sell your characters and get cookies for it',
			button: 'Collections'
		},
		search: {
			results: 'Search results',
			empty: 'Unfortunately, nothing was found',
			inputAria: 'Character variation',
			buttonAria: 'Find a variation'
		},
		characterSale: {
			modalTitle: 'Are you ready to sell a hero?',
			buttonText: 'Sell for'
		},
		characterBuy: {
			modalTitle: 'Buy hero?',
			buttonText: 'Buy for',
			successModalDesc: 'The hero has been successfully purchased'
		},
		constructor: {
			buyCharacter: 'Buy the character',
			myHero: 'My hero'
		},
		login: {
			registration: 'Registration',
			registrationVerb: 'Register',
			registrationEnd: 'Finalize registration',
			registrationEndDesc: 'Confirm your email address to complete registration.',
			registrationEndConfirm: 'Confirm now',
			registerDesc:
				'Create an account to get to know all the characters. It only takes a couple minutes!',
			signIn: 'Sign In',
			signInVerb: 'Sign in',
			signInGoogle: 'Sign in with Google',
			signInFacebook: 'Sign in with Facebook',
			signInVK: 'Sign in with vk',
			signInEmail: 'Sign in with Email',
			signUpButtonText: 'Sign up',
			iAgreeText: 'I accept',
			iAgreeLink: 'terms and conditions for processing personal data',
			inputEmail: 'Email',
			inputPassword: 'Password',
			inputPasswordRepeat: 'Repeat password',
			showHidePassword: 'Show/Hide Password',
			requiredField: 'This is a required field',
			acceptTerms: 'Accept Agreement',
			incorrectEmail: 'Invalid Email',
			incorrectPassword: 'Invalid Password',
			incorrectPasswordType:
				'Password must contain 6 to 18 characters, Latin letters only, including 1 uppercase letter',
			incorrectPasswordMatch: 'Passwords do not match',
			incorrectCredentials: 'Incorrect email or password'
		},
		sign_up: {
			mail_used: 'A user with this Email already exists'
		},
		authGift: {
			openButton: 'Open a gift',
			saveButton: 'Save to collection',
			saveToCollection: 'Save to collection',
			registerDesc:
				'Enter your phone number to get the code and save the character to your collection',
			invalidQr: 'QR-code is not valid',
			usedQr: 'This QR-code has already been used',
			qrNotFound: 'This QR-code was not found',
			qrErrorButtonText: 'Home',
			qrErrorContactButtonText: 'Contact support',
			qrSuccessText: 'A character has been added to your collection',
			qrSuccessButtonText: 'My collection'
		},
		favorites: {
			openInConstructor: 'Open in the constructor',
			buyHero: 'Buy hero',
			actions: 'Actions',
			hide: 'Hide',
			delete: 'Delete',
			deleteConfirm: 'Are you sure you want to remove the hero from your favorites?',
			createVariant: 'Create a variant'
		},
		myHeroes: {
			tapOnMe: 'Click on me',
			actions: 'Actions',
			value: 'Value',
			saveImage: 'Save a photo of the character',
			exchange: 'Exchange',
			gift: 'Gift',
			sell: 'Sell',
			hide: 'Hide',
			purchase: 'Purchase',
			buyAnimConfirmText: 'Are you sure you want to add this animation?',
			addFor: 'Add for',
			giftConfirmText: 'Are you sure you want to send a gift?',
			send: 'Send',
			tapHint: 'To place a character, first place it in the desired location by rotating and zooming the phone, and then click once on the screen - the character will remain standing at the selected point.',
			okay: 'Okay'
		},
		gift: {
			hint: 'Show the QR-code to the gift recipient',
			receiveConfirm: 'Would you like to accept the gift?',
			receiveButtonText: 'Accept',
			successSent: 'Gift successfully sent',
			successSendButtonText: 'My collection',
			successReceive: 'Gift has been received, you can find it in your collection',
			successReceiveButtonText: 'My collection'
		},
		exchange: {
			hint: 'Scan to exchange heroes',
			suggestedExchange: 'You suggested an exchange',
			awaitingReply: 'Awaiting reply',
			exchangeOfferText: "You're being offered an exchange",
			selectCharacter: 'Choose a character from your collections to trade for',
			hisHero: 'His hero',
			yourHero: 'Your hero',
			choose: 'Choose',
			refuse: 'Refuse',
			selectCollection: 'Select a collection',
			next: 'Next',
			suggest: 'Suggest',
			confirmation: 'Confirmation',
			confirmationDesk:
				'Confirm the terms and conditions of the exchange if you agree to its terms and conditions',
			confirmButtonText: 'Confirm',
			successCompleted: 'Exchange successfully completed',
			successButtonText: 'My collection',
			characterAlreadyExist: 'The character is already in your collection',
			noOneCharacter: "You don't have any characters"
		},
		errorModal: {
			title: 'Oops',
			desc: 'Something went wrong. Try again',
			buttonText: 'Ok'
		},
		pwaModal: {
			title: 'How to add an application icon?',
			subTitle1: 'Click the share button',
			subTitle2: 'Add to Home Screen',
			subTitle3: 'Click “Add”',
			addButtonText: 'Add',
			finishButtonText: 'Done'
		},
		welcomeModal:{
			makeCollection: 'Build a collection',
			makeCollectionDesc: 'Buy items with Monzi and get unique AR characters (monzies) for your collection',
			valueRare: 'Value the rare ones!',
			valueRareDesc: 'Each Monzi has traits, things and items with different rarity, the more rare the item or trait, the higher the value',
			upgradeCharacters: 'Upgrade Characters',
			upgradeCharactersDesc: 'Push as much as you can on your monzies to accumulate energy that can be used to animate them',
			improveCollection: 'Improve your collection',
			improveCollectionDesc: 'Exchange monzies with other users, and accumulate a few and sell them to buy the hero you want.',
			makeAr: 'Take an AR photo or video',
			makeArDesc: 'Publish a photo or video of your monzik on social networks: dance and have fun with it so everyone can appreciate it!'
		},
		inDevModal: {
			title: 'Character in development',
			desc: 'This character is in development, so it is not available yet'
		},
		arHelpModal: {
			title: 'AR functions',
			desc:
				'<b>1. Rotation.</b> Swipe the character with one finger' +
				'<br /><br /><b>2. Scaling.</b> You use two fingers to zoom in on a character' +
				'<br /><br /><b>3. Animation.</b> Click on the animation to start it' +
				'<br /><br /><b>4. Hero placement.</b> The button at the bottom right of the screen will allow you to reposition your character' +
				'<br /><br /><b>5. Monzik photo.</b> To take a photo or video of the monzik, ask someone to take a picture of you with the monzik',
			buttonText: 'Okay'
		},
		crystalModal: {
			rareDesc: 'Rare item. 40% chance of drop',
			mythicalDesc: 'Mythical item. 10% chance of drop',
			legendaryDesc: 'Legendary item. 5% chance of drop'
		},
		externalErrors: {
			qrCodeRequiredTitle: 'Purchase an item with a QR code',
			qrCodeRequired:
				'Registration is only possible for users who have purchased a product with Monzi'
		},
		web_ar: {
			download_button: 'download'
		},
		landing: { ...landingEn }
	}
}
