// Component that places trees at cursor location when screen is tapped
AFRAME.registerComponent('tap-to-place', {
	schema: {
		surfaceType: { type: 'string', default: 'ground' }, // ground or wall
		bounds: { type: 'string', default: '4,40' }, // Format: 'min,max'
		isPlaced: { type: 'boolean', default: false }
	},
	init() {
		this.raycaster = new window.THREE.Raycaster();
		this.camera = document.getElementById('camera');
		this.threeCamera = this.camera.getObject3D('camera');
		this.surface = document.getElementById(this.data.surfaceType);
		this.rayOrigin = new window.THREE.Vector2(0, 0);
		this.cursorLocation = new window.THREE.Vector3(0, 0, 0);

		// Parse checkBounds string into min and max
		if (this.data.bounds) {
			const [min, max] = this.data.bounds.split(',').map(Number);
			this.bounds = { min, max };
		}
	},
	checkBounds(coordinates) {
		if (coordinates && this.bounds) {
			const result = this.camera.object3D.position.distanceTo(coordinates);
			return result > this.bounds.min && result < this.bounds.max;
		}
		return true; // If bounds are not set, always return true
	},
	tick() {
		const type = this.data.surfaceType;

		if ((type === 'wall' && this.data.isPlaced) || (type === 'ground' && this.data.isPlaced)) {
			return;
		}

		// Perform raycasting
		this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera);
		const intersects = this.raycaster.intersectObject(this.surface.object3D, true);

		if (intersects.length > 0) {
			const [intersect] = intersects;
			this.cursorLocation = intersect.point;
		}

		if (!this.checkBounds(this.cursorLocation)) {
			return; // Skip updating position if out of bounds
		}

		// Handle surface-specific adjustments
		if (this.data.surfaceType === 'ground') {
			this.el.object3D.position.y = 0.1;
		} else if (this.data.surfaceType === 'wall') {
			this.el.object3D.position.z = 0.1;
		}

		this.el.object3D.position.lerp(this.cursorLocation, 0.4);
		this.el.object3D.rotation.y = this.threeCamera.rotation.y;
	}
});
