import { getFromCache, removeFromCache } from '@/helpers/localStorage.js'

export const HOST = import.meta.env.VITE_API_URL

export const OPTIONS = {
	method: 'GET',
	headers: {
		'Content-Type': 'application/json'
	}
}

export async function request(func) {
	try {
		return await func()
	} catch (err) {
		console.error(err)
	}
}

export function checkAuthorization(response) {
	const token = getFromCache('access_token')

	if (response.status === 401 && token) {
		removeFromCache('access_token')
		window.location.reload()
	}
}

export function getOptions(authHeaders) {
	return {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeaders
		}
	}
}
