import { createI18n } from 'vue-i18n'
import { enMessages } from '@/i18n/messages/en.js'
import { ruMessages } from '@/i18n/messages/ru.js'
import { plMessages } from '@/i18n/messages/pl.js'
import { deMessages } from '@/i18n/messages/de.js'
import { getCurrentLocale } from '@/helpers/currentLocale.js'

const i18n = createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: {
		...enMessages,
		...ruMessages,
		...plMessages,
		...deMessages
	},
	warnHtmlInMessage: 'off'
})

getCurrentLocale()

export default i18n
