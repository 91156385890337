export default {
	general: {},
	characters: {
		roxxid: {
			name: 'Roxid',
			excerpt:
				'Roxid to cybernetyczny poszukiwacz przygód znany ze swoich śmiałych przygód w wirtualnych światach. Jego kolorowy kostium i zaawansowane technologicznie gogle pozwalają mu dostosować się do każdego środowiska. Zawsze szuka nowych wyzwań i przygód.',
			desc: '<p><strong>Roxid</strong> to cybernetyczny poszukiwacz przygód znany ze swoich śmiałych przygód w wirtualnych światach. Jego kolorowy kostium i zaawansowane technologicznie gogle pozwalają mu dostosować się do każdego środowiska. Zawsze szuka nowych wyzwań i przygód.</p><p><strong>Rasa:</strong> człowiek<br><strong>Charakter:</strong> Niechętny, Inteligentny, Bezinteresowny</p>'
		},
		mila: {
			name: 'Cutie',
			excerpt:
				'Cutie to marzycielska, romantyczna dziewczyna, która zawsze nosi słodkie rzeczy. Uwielbia słodkie kolory i miękkie tkaniny, które odzwierciedlają jej miłą i zabawną naturę. Zawsze gotowa wesprzeć swoich przyjaciół i wnieść kroplę magii i radości do każdego dnia.',
			desc: '<p><strong>Cutie</strong> to marzycielska, romantyczna dziewczyna, która zawsze nosi słodkie rzeczy. Uwielbia słodkie kolory i miękkie tkaniny, które odzwierciedlają jej miłą i zabawną naturę. Zawsze gotowa wesprzeć swoich przyjaciół i wnieść kroplę magii i radości do każdego dnia.</p><p><strong>Rasa:</strong> człowiek<br><strong>Charakter:</strong> Słodka, empatyczna, oszczędna</p>'
		},
		beak: {
			name: 'Birdman',
			excerpt:
				'Birdman jest enigmatycznym wojownikiem z bystrym okiem do rozwiązywania wszelkich zagadek. Jego strój może wzbudzać zarówno strach, jak i uśmiech. Cichy i poważny, ale kiedy mówi, jego słowa są zawsze pełne mądrości i mocy.',
			desc: '<p><strong>Birdman</strong> jest enigmatycznym wojownikiem z bystrym okiem do rozwiązywania wszelkich zagadek. Jego strój może wzbudzać zarówno strach, jak i uśmiech. Cichy i poważny, ale kiedy mówi, jego słowa są zawsze pełne mądrości i mocy.</p><p><strong>Rasa:</strong> Ptaki<br><strong>Charakter:</strong> Tajemniczy, Uczciwy, Lojalny</p>'
		},
		skeleton: {
			name: 'Skeleton',
			excerpt:
				'Skeleton to żywy duch przygody i zabawy, zawsze gotowy do działania. Jego stylowy strój idzie w parze ze swobodnym podejściem do życia i śmiałym duchem. Pomimo onieśmielającego wyglądu, ma wesołą osobowość i pragnie pokazać, że śmiech i odwaga idą w parze.',
			desc: '<p><strong>Skeleton</strong> to żywy duch przygody i zabawy, zawsze gotowy do działania. Jego stylowy strój idzie w parze ze swobodnym podejściem do życia i śmiałym duchem. Pomimo onieśmielającego wyglądu, ma wesołą osobowość i pragnie pokazać, że śmiech i odwaga idą w parze.</p><p><strong>Rasa:</strong> Stworzenia<br><strong>Charakter:</strong> Wesoły, Uprzejmy, Odważny</p>'
		}
	},
	products: {
		product_1: {
			name: 'Ciasteczka z wróżbą w markowym pudełku'
		},
		product_2: {
			name: 'Ciasteczka z wróżbą w markowym pudełku'
		},
		product_3: {
			name: 'Ciasteczka z wróżbą w markowym pudełku'
		}
	},
	markets: {
		amazon: {
			name: 'Amazon'
		},
		allegro: {
			name: 'Allegro'
		},
		funcookies: {
			name: 'Funcookies'
		},
		wildberries: {
			name: 'Wildberries'
		}
	},
	news: {
		news_1: {
			title: 'MONZI. Poznaj swoich bohaterów',
			desc: '<p>4 absolutnie wyjątkowe postacie, każda z własną historią i cechami, gotowe wyruszyć na niezapomnianą przygodę w Twoim świecie. Dowiedz się kim są i jak zmienią Twoją rzeczywistość!</p>'
		},
		news_2: {
			title: 'Więcej MONZI - więcej możliwości',
			desc: '<p>Każdy nowy bohater to krok w kierunku pełnej kolekcji i nowych umiejętności. Bądź jednym z pierwszych, którzy zbiorą wszystkie MONZI, wymieniaj się z przyjaciółmi i zwiększaj możliwości swoich bohaterów do maksimum!</p>'
		},
		news_3: {
			title: 'Opowiedz swoją historię MONZI: Kto będzie Twoim ulubieńcem?',
			desc: '<p>Roxid, Cutie, Birdman i Skeleton to czwórka wyjątkowych bohaterów gotowych na ekscytujące przygody! Wybierz swojego faworyta i podziel się swoimi postępami ze znajomymi w mediach społecznościowych!</p>'
		},
		news_4: {
			title: 'MONZI: Twoi bohaterowie, twoje zasady',
			desc: '<p>Wszystkie postacie mają wiele opcji wyglądu i czekają na Ciebie spektakularne animacje. Który wygląd stworzysz? Przekształć swojego MONZI w wyjątkową postać, która będzie odzwierciedlać tylko Ciebie!</p>'
		}
	},
	product_links: {
		buy_on_amazon: 'kup na  Amazon',
		buy_on_wildberries: 'kup na Wildberries',
		buy_on_allegro: 'kup na Allegro',
		buy_on_funcookies: 'kup na FUNCOOKIES'
	},
	components: {
		app_footer: {},
		app_header: {
			scene: 'Scena',
			show_qr: 'Pokaż kod QR'
		},
		app_modal: {
			close: 'Zamknij'
		}
	},
	home_view: {
		home_hero: {
			title: 'MONZI AR Heroes Collection',
			desc: 'Nie przegap okazji, aby zagrać w ekscytującą grę, która sprawi, że zagubisz się w świecie przygód',
			link: 'PRZEJDŹ DO PRODUKTÓW',
			cards: {
				card_1: {
					title: 'POKAŻ ZNAJOMYM',
					desc: 'Rób filmy lub zdjęcia AR i udostępniaj je w sieciach społecznościowych'
				},
				card_2: {
					title: 'PODKRĘĆ SWOICH BOHATERÓW',
					desc: 'Gromadź energię i wykorzystuj ją do animacji'
				},
				card_3: {
					title: 'SPRZEDAJ MONZIE',
					desc: 'Nie przegap okazji, aby zagrać w ekscytującą grę, która sprawi, że zagubisz się w świecie przygód'
				},
				card_4: {
					title: 'HANDLUJ POSTACIAMI',
					desc: 'Nie przegap okazji, aby zagrać w ekscytującą grę, która sprawi, że zagubisz się w świecie przygód'
				}
			}
		},
		home_about: {
			title: 'O BOHATERACH KOLEKCJI',
			subtitle: 'O OSOBACH'
		},
		home_how_it_works: {
			title: 'JAK TO DZIAŁA',
			cards: {
				card_1: {
					title: 'Kup ciasteczka',
					desc: 'Kup produkt z postacią MONZI w środku'
				},
				card_2: {
					title: 'ODBIERZ MONZI',
					desc: 'Zeskanuj kod QR na produkcie i zdobądź MONZI!'
				},
				card_3: {
					title: 'ZBIERAJ',
					desc: 'Zbuduj swoją unikalną kolekcję i wymieniaj się MONZI z przyjaciółmi'
				}
			}
		},
		home_products: {
			title: 'Produkty z MONZI',
			buy_btn: 'KUP'
		},
		home_markets: {
			title: 'Gdzie można kupić'
		},
		home_news: {
			title: 'Wiadomości',
			show_more: 'Pokaż więcej'
		},
		home_faq: {
			title: 'FAQ',
			show_answer: 'Pokaż odpowiedź',
			card_1: {
				title: 'Jak uzyskać animację na postaci?',
				desc: 'Możesz kupić animację dla swojej postaci za energię. Energia jest gromadzona po kliknięciu na postać w sekcji „Moje postacie”'
			},
			card_2: {
				title: 'Jak uzyskać konkretną postać z pliku cookie?',
				desc: 'Nie możesz otrzymać konkretnej postaci, wszystkie postacie są generowane losowo i nikt nie wie, jaką postać otrzymasz po zeskanowaniu kodu QR. Możesz jednak kupić postać za wirtualną walutę „Cookies”. Możesz ją zarobić, sprzedając postacie ze swojej kolekcji'
			},
			card_3: {
				title: 'Co mogę zrobić z postaciami Monzi?',
				desc: 'Możesz wymieniać, dawać, sprzedawać, kupować i handlować postaciami Monzi. Możesz także robić zdjęcia swoich postaci w rzeczywistości rozszerzonej i udostępniać je w sieciach społecznościowych. Masz możliwość spersonalizowania swojej postaci Monzi poprzez przymierzanie na niej różnych elementów odzieży i akcesoriów'
			},
			card_4: {
				title: 'Dlaczego niektóre postacie Monzi są droższe?',
				desc: 'Niektóre postacie są w rzeczywistości droższe, ponieważ są rzadsze, a szansa na ich wypadnięcie jest mniejsza'
			}
		}
	},
	privacy_policy_view: {
		title: 'Polityka Prywatnoś&shy;ci',
		content: `<p>Niniejszy dokument „Polityka Prywatności” (dalej zwany „Polityką”) określa zasady korzystania z danych użytkowników internetu przez /właściciel strony/ (dalej „my” i/lub „Administracja”), które są zbierane za pośrednictwem strony internetowej /URL strony/ (dalej „Strona”).</p>
				<h6>1. Przetwarzane dane</h6>
				<p>1.1. Nie zbieramy Twoich danych osobowych za pośrednictwem Strony.</p>
				<p>1.2. Wszelkie dane zbierane na Stronie są przekazywane i przyjmowane w formie anonimowej (dalej „Dane Anonimowe”).</p>
				<p>1.3. Dane Anonimowe obejmują następujące informacje, które nie pozwalają na Twoją identyfikację:</p>
				<p>1.3.1. Informacje, które samodzielnie podajesz o sobie za pomocą formularzy online i modułów programowych Strony, w tym imię oraz numer telefonu i/lub adres e-mail.</p>
				<p>1.3.2. Dane, które są przekazywane w sposób anonimowy w trybie automatycznym, w zależności od ustawień używanego przez Ciebie oprogramowania.</p>
				<p>1.4. Administracja ma prawo ustalać wymagania dotyczące składu Danych Anonimowych Użytkownika zbieranych za pośrednictwem Strony.</p>
				<p>1.5. Jeśli określone informacje nie są oznaczone jako obowiązkowe, ich podanie lub ujawnienie odbywa się według Twojego uznania. Jednocześnie wyrażasz świadomą zgodę na dostęp do takich danych przez nieograniczone grono osób. Dane te stają się powszechnie dostępne w momencie ich podania i/lub ujawnienia w innej formie.</p>
				<p>1.6. Administracja nie weryfikuje dokładności przekazywanych danych ani posiadania przez Użytkownika niezbędnej zgody na ich przetwarzanie zgodnie z niniejszą Polityką, zakładając, że Użytkownik działa w dobrej wierze, z należytą starannością i dokłada wszelkich starań, aby utrzymywać takie informacje w stanie aktualnym oraz uzyskiwać wszelkie niezbędne zgody na ich wykorzystanie.</p>
				<p>1.7. Przyjmujesz i akceptujesz możliwość korzystania na Stronie z oprogramowania stron trzecich, w wyniku czego takie osoby mogą otrzymywać i przekazywać dane określone w pkt. 1.3 w formie anonimowej.</p>
				<p><strong>Przykład!</strong> Do takiego oprogramowania stron trzecich należą systemy zbierania statystyk odwiedzin, takie jak Google Analytics i Yandex.Metrica.</p>
				<p>1.8. Skład i warunki zbierania Danych Anonimowych za pomocą oprogramowania stron trzecich są określane bezpośrednio przez ich właścicieli praw i mogą obejmować:</p>
				<ul>
				<li>dane przeglądarki (typ, wersja, pliki cookie);</li>
				<li>dane urządzenia oraz jego lokalizację;</li>
				<li>dane systemu operacyjnego (typ, wersja, rozdzielczość ekranu);</li>
				<li>dane żądania (czas, źródło przekierowania, adres IP).</li>
				</ul>
				<p>1.9. Administracja nie ponosi odpowiedzialności za sposób wykorzystania Danych Anonimowych Użytkownika przez strony trzecie.</p>
				<h6>2. Cele przetwarzania danych</h6>
				<p>2.1. Administracja wykorzystuje dane w następujących celach:</p>
				<p>2.1.1. Przetwarzanie zgłoszeń i kontakt z Użytkownikiem;</p>
				<p>2.1.2. Obsługa informacyjna, w tym wysyłka materiałów reklamowo-informacyjnych;</p>
				<p>2.1.3. Prowadzenie badań marketingowych, statystycznych i innych;</p>
				<p>2.1.4. Ukierunkowanie materiałów reklamowych na Stronie.</p>
				<h6>3. Wymagania dotyczące ochrony danych</h6>
				<p>3.1. Administracja przechowuje dane i chroni je przed nieautoryzowanym dostępem oraz rozpowszechnianiem zgodnie z wewnętrznymi zasadami i regulacjami.</p>
				<p>3.2. W odniesieniu do uzyskanych danych zachowuje się poufność, z wyjątkiem przypadków, gdy zostały one udostępnione publicznie przez Użytkownika, a także gdy technologie i oprogramowanie stron trzecich używane na Stronie lub ustawienia używanego przez Użytkownika oprogramowania przewidują otwartą wymianę danych z tymi osobami i/lub innymi uczestnikami oraz użytkownikami sieci internetowej.</p>
				<p>3.3. W celu poprawy jakości pracy Administracja ma prawo przechowywać pliki dziennika (logi) dotyczące działań podjętych przez Użytkownika w ramach korzystania ze Strony przez okres 1 (jednego) roku.</p>
				<h6>4. Przekazywanie danych</h6>
				<p>4.1. Administracja ma prawo przekazać dane stronom trzecim w następujących przypadkach:</p>
				<ul>
				<li>Użytkownik wyraził na to zgodę, w tym w przypadkach, gdy Użytkownik stosuje ustawienia używanego oprogramowania, które nie ograniczają udostępniania określonych informacji;</li>
				<li>Przekazanie jest konieczne w ramach korzystania przez Użytkownika z funkcji Strony;</li>
				<li>Przekazanie jest wymagane zgodnie z celami przetwarzania danych;</li>
				<li>W związku z przekazaniem Strony na własność, użytkowanie lub posiadanie takiej strony trzeciej;</li>
				<li>Na żądanie sądu lub innego upoważnionego organu państwowego w ramach określonej procedury prawnej;</li>
				<li>W celu ochrony praw i prawnie uzasadnionych interesów Administracji w związku z naruszeniami popełnionymi przez Użytkownika.</li>
				</ul>
				<h6>5. Zmiana Polityki Prywatności</h6>
				<p>5.1. Niniejsza Polityka może zostać zmieniona lub zakończona przez Administrację jednostronnie, bez uprzedniego powiadomienia Użytkownika. Nowa wersja Polityki wchodzi w życie z chwilą jej opublikowania na Stronie, chyba że nowa wersja Polityki stanowi inaczej.</p>
				<p><strong>Politykę opracował <a href="https://monzi.io/">monzi.io</a></strong></p>`
	},
	not_found_view: {
		title: 'Nie znaleziono strony',
		description: 'Ups! Coś poszło nie tak, ta strona nie istnieje',
		btn_text: 'Strona główna'
	},
	modals: {
		character: {
			title: 'O OSOBACH:',
			subtitle: 'Kup produkt z postacią MONZI w środku'
		},
		question_form: {
			successTitle: 'Twoje pytanie zostało pomyślnie wysłane!',
			successDesc:
				'Dziękujemy za przesłane zapytanie! Wkrótce odpowiemy na Twój adres e-mail. Życzymy miłego dnia!',
			successButtonText: 'zrozumiały!',
			errorTitle: 'Ups... wystąpił<br />błąd podczas<br />wysyłania<br />wiadomości',
			errorDesc: 'Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później',
			errorButtonText: 'Spróbuj ponownie'
		}
	},
	forms: {
		question: {
			title: 'Pytania?',
			desc: 'Zapytaj wsparcie',
			email_placeholder: 'Twój e-mail',
			question_placeholder: 'Twoje pytanie',
			button_text: 'Wyślij',
			checkbox_text:
				'Wyrażasz zgodę na <a href="/privacy-policy">przetwarzanie swoich</a> danych osobowych',
			link_text: 'polityka prywatności'
		}
	},
	footer: {
		title: 'INFORMACJE O FIRMIE',
		sng_region_info: 'Belarus LLC "Fabrica zhelaniy" 220024, Minsk st. Babushkina 66-17',
		other_region_info:
			'Poland: Fortune cookies Sp. z o.o, 01-171, Warszawa, ul. Młynarska , 42-115'
	},
	header: {
		links: {
			home: 'główny',
			about: 'O BOHATERACH',
			how_it_works: 'JAK TO DZIAŁA',
			products: 'Produkty z MONZI',
			markets: 'GDZIE KUPIĆ',
			news: 'Wiadomości',
			faq: 'FAQ'
		}
	},
	validator: {
		required: 'To pole jest obowiązkowe',
		email: 'To pole musi zawierać Twój adres e-mail'
	}
}
