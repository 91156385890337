export const clickOutsideDirective = {
	mounted(el, binding) {
		el.clickOutsideEvent = function (event) {
			const { handler, exclude } = binding.value
			const clickedInsideModal = el.contains(event.target)
			const clickedOnExcludedEl = exclude?.contains(event.target)

			if (!clickedInsideModal && !clickedOnExcludedEl) {
				handler(event)
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unmounted(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	}
}
